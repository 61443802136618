import { WtxColors } from '@wavetronix/common-components'
import { useMemo, useState } from 'react'
import {
  Bar,
  Brush,
  CartesianGrid,
  ComposedChart,
  Label,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import { ColorOptions } from '../Utils/ColorOptions'
import { usDollar } from '../Utils/CurrencyConversion'
import ShortagesModal from './ShortagesModal'

const checkProductZeros = (fillable, backfillable, remaining) => {
  if (fillable === 0 && backfillable === 0 && remaining === 0) {
    return true
  } else {
    return false
  }
}

// https://stackoverflow.com/questions/55301556/recharts-tooltip-for-each-bar-in-a-bar-chart-composed-of-three-bars
const CustomTooltip = ({ active, payload, tooltip }) => {
  if (!active || !tooltip || !payload) return null
  for (const bar of payload)
    if (bar.dataKey === tooltip) {
      let itemNumber = bar.name.split('backfillable')[0].split('fillable')[0].split('remaining')[0]
      let demand = bar.name.split(itemNumber)[1].split('Rev')[0]
      let demandLabel = demand.charAt(0).toUpperCase() + demand.slice(1)
      return (
        <div
          style={{
            backgroundColor: bar.color,
            padding: '20px',
            opacity: 0.9,
            border: '2px solid white',
            borderRadius: '15px',
            color: 'white'
          }}>
          Item: {itemNumber}
          <br />
          Revenue: {usDollar.format(bar.value.toFixed(2))}
          <br />
          {demandLabel}: {bar.payload[bar.dataKey.substring(0, bar.dataKey.length - 3)]}
          <br />
          Demand: {bar.payload[`${itemNumber}totalDemand`]}
        </div>
      )
    }
  return null
}

const CustomLabel = ({ value, data, id, fillable, backfillable, remaining, x, y, width, showAllProducts, labels }) => {
  if (!value || labels === false) return null
  if (showAllProducts === false && checkProductZeros(data[id][fillable], data[id][backfillable], data[id][remaining])) {
    return null
  }

  const length = value.toString().length
  const offset = (width - length * 7) / 2
  return (
    <text x={x + offset} y={y - 5} fill={'black'}>
      {value}
    </text>
  )
}

function HighLevelProjectionGraph({ data, products, variant, labels, showAllProducts, partShortages }) {
  const [tooltip, setToolTip] = useState()
  const [shortagesModalOpen, setShortagesModalOpen] = useState(false)
  const [selectedShortages, setSelectedShortages] = useState()

  const legend = [
    { value: 'Cannot Build', type: 'rect', color: WtxColors.INNOVATION_RED },
    { value: 'Back Fill', type: 'rect', color: WtxColors.GROWTH_GREEN }
  ]
  const graphBars = useMemo(() => {
    let bars = []
    if (data) {
      for (let i = 0; i < products.length; i++) {
        let fillable = `${products[i]}fillableRev`
        let backfillable = `${products[i]}backfillableRev`
        let remaining = `${products[i]}remainingRev`
        let itemNumber = `${products[i]}`
        bars.push(
          <Bar
            key={i}
            dataKey={fillable}
            stackId={itemNumber}
            onMouseOver={() => setToolTip(fillable)}
            fill={Object.keys(ColorOptions).map(key => ColorOptions[key])[i % Object.keys(ColorOptions).length]}
          />
        )
        bars.push(
          <Bar
            key={i + products.length}
            dataKey={backfillable}
            stackId={itemNumber}
            onMouseOver={() => setToolTip(`${products[i]}backfillableRev`)}
            fill='#00c473'
          />
        )
        bars.push(
          <Bar
            key={i + 2 * products.length}
            dataKey={remaining}
            stackId={itemNumber}
            style={{ cursor: 'pointer' }}
            onMouseOver={() => setToolTip(remaining)}
            onClick={e => {
              setSelectedShortages(
                variant === 'summary' ? data[0][`${products[i]}shortages`] : data[e.id][`${products[i]}shortages`]
              )
              setShortagesModalOpen(true)
            }}
            fill='#d93240'>
            <LabelList
              dataKey={itemNumber}
              position='top'
              style={{ display: `${labels ? '' : 'none'}` }}
              content={
                <CustomLabel
                  data={data}
                  fillable={fillable}
                  backfillable={backfillable}
                  remaining={remaining}
                  showAllProducts={showAllProducts}
                  labels={labels}
                />
              }
            />
          </Bar>
        )
      }
    }
    return bars
  }, [data, products, variant, labels, showAllProducts])

  return (
    <>
      <ShortagesModal
        open={shortagesModalOpen}
        onClose={() => setShortagesModalOpen(false)}
        shortages={selectedShortages}
        data={data}
        partShortages={partShortages}
      />
      <ResponsiveContainer width='99%' height={500}>
        <ComposedChart
          data={data}
          margin={{
            top: 15,
            right: 10,
            left: 20,
            bottom: 40
          }}
          animationEasing='linear'
          barCategoryGap='6%'
          barGap={10}>
          <CartesianGrid strokeDasharray='3 3 1 3 3 3 1 3 3 3 1 1 1 3 1 1 3' />
          {variant === 'summary' ? <></> : <Brush dataKey='month' height={30} stroke={WtxColors.ULTRA_VIOLET} y={468} />}
          <XAxis dataKey='month'>
            <Label value='Time Period' offset={-8} position='insideBottom' />
          </XAxis>
          <YAxis tickFormatter={v => (v < 1000 ? `${v}` : `${v / 1000}K`)}>
            <Label value='Revenue' offset={-10} position='insideLeft' angle={-90} />
          </YAxis>
          <Tooltip content={<CustomTooltip tooltip={tooltip} />} />
          {graphBars}
          <Legend verticalAlign='bottom' align='left' offset={50} payload={legend} />
        </ComposedChart>
      </ResponsiveContainer>
    </>
  )
}
export default HighLevelProjectionGraph
