import React from 'react'
import { CartesianGrid, Label, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { usDollar } from '../Utils/CurrencyConversion'

export default function MonthlySummaryGraph({ data }) {
  return (
    <ResponsiveContainer width='99%' height={500}>
      <LineChart
        margin={{
          top: 15,
          right: 10,
          left: 20,
          bottom: 40
        }}>
        <CartesianGrid strokeDasharray='3 3 1 3 3 3 1 3 3 3 1 1 1 3 1 1 3' />
        <XAxis dataKey='month' type='category' allowDuplicatedCategory={true} padding={{ left: 30, right: 30 }}>
          <Label value='Month' offset={-8} position='insideBottom' />
        </XAxis>
        <YAxis dataKey='revenue' tickFormatter={v => (v < 1000 ? `${v}` : `${v / 1000}K`)}>
          <Label value='Revenue' offset={-10} position='insideLeft' angle={-90} />
        </YAxis>
        <Tooltip formatter={value => usDollar.format(value.toFixed(2))} />
        <Line dataKey='revenue' data={data} isAnimationActive={false} />
      </LineChart>
    </ResponsiveContainer>
  )
}
