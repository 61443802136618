import { useMsal } from '@azure/msal-react'
import { CircularProgress, Grid } from '@mui/material'
import {
  acquireAccessToken,
  CenteredDiv,
  CustomDataGrid,
  ErrorMessage,
  getConfiguration,
  PrimaryButton,
  SnackbarVariants
} from '@wavetronix/common-components'
import { useState, useEffect } from 'react'
import { useSnackbar } from 'notistack'
import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import { useLocation } from 'react-router-dom'
import { env } from '../../index.js'
import qs from 'qs'
import SubstitutesList from '../SubstitutesList'
import { downloadBlob } from '../Menus/OptionsMenu'
import GetAppIcon from '@mui/icons-material/GetApp'
import { getProductCategory } from '../../Utils/ProductsMap'

const ORDER_COLUMNS = [
  {
    field: 'promisedDeliveryDate',
    headerName: 'Promised Delivery Date',
    valueFormatter: ({ value }) => value.split('T')[0],
    disableColumnMenu: true,
    flex: 1
  },
  {
    field: 'quantity',
    headerName: 'Quantity',
    disableColumnMenu: true,
    flex: 1
  }
]

const BOM_COLUMNS = [
  {
    field: 'itemNumber',
    headerName: 'Item Number',
    disableColumnMenu: true,
    flex: 0.5
  },
  {
    field: 'description',
    headerName: 'Description',
    disableColumnMenu: true,
    flex: 1
  },
  {
    field: 'needsQuantity',
    headerName: 'Quantity Needed',
    disableColumnMenu: true,
    flex: 0.5
  },
  {
    field: 'substitutes',
    headerName: 'Substitutes',
    renderCell: row => <SubstitutesList substitutes={row.value} />,
    disableColumnMenu: true,
    flex: 1
  }
]

export default function PartInfoPage() {
  const { accounts, instance } = useMsal()
  const { enqueueSnackbar } = useSnackbar()
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const location = useLocation()
  let querystring = ''
  if (location.search.length > 0 && location.search[0] === '?') {
    querystring = location.search.substring(1)
  }
  let itemNumber = qs.parse(querystring).itemNumber
  let shortages = qs.parse(querystring).shortages !== 'undefined' ? qs.parse(querystring).shortages.replaceAll('^', '\n') : ''

  async function getPartInfo() {
    let token = await acquireAccessToken(instance, accounts, env)
    return axios
      .get(`${env.urls.inventoryProjectorURL}/api/parts/${itemNumber}`, getConfiguration(token))
      .catch(e => {
        enqueueSnackbar(`${e.response.data}`, SnackbarVariants.ERROR)
      })
      .then(res => res.data)
  }

  const { isLoading, data, error } = useQuery({ queryKey: ['partInfo'], queryFn: getPartInfo })

  useEffect(() => {
    let disable = true
    if (data && shortages) {
      let values = Object.keys(data.partInfo.productInfo).map(key => getProductCategory(key))
      for (const v of values) {
        if (shortages.includes(v)) {
          disable = false
        }
      }
    }
    setButtonDisabled(disable)
  }, [data, shortages])

  if (error) {
    return <ErrorMessage error={error} />
  }
  if (isLoading) {
    return (
      <CenteredDiv>
        <CircularProgress />
      </CenteredDiv>
    )
  } else {
    return (
      <>
        <div style={{ marginLeft: '20px' }}>
          <div style={{ display: 'flex' }}>
            <h1>{data.partInfo.id}</h1>
            <PrimaryButton
              disabled={buttonDisabled}
              style={{ height: 40, marginRight: '20px', marginLeft: 'auto', marginTop: '30px' }}
              onClick={() => {
                downloadBlob(shortages, `Part Shortages for Item Number ${data.partInfo.id}.csv`, 'text/csv;charset=utf-8;')
              }}>
              Projected Shortages &nbsp;
              <GetAppIcon />
            </PrimaryButton>
          </div>

          <h2>Description: {data.partInfo.description}</h2>
          <h2>Current Inventory: {data.currentInventory}</h2>
        </div>
        <Grid style={{ width: '100%' }} container spacing={2}>
          <Grid style={{ width: '40%' }}>
            <h2 style={{ marginLeft: '24px' }}>Incoming Orders:</h2>
            <CustomDataGrid
              style={{ margin: 24 }}
              rows={data.incomingOrders.map((x, i) => ({ ...x, id: i }))}
              columns={ORDER_COLUMNS}
              loading={isLoading}
            />
          </Grid>
          <Grid style={{ width: '60%' }}>
            <h2 style={{ marginLeft: '24px' }}>Bills of Materials:</h2>
            <CustomDataGrid
              rows={Object.keys(data.partInfo.productInfo).map(key => ({ ...data.partInfo.productInfo[key], id: key }))}
              columns={BOM_COLUMNS}
              loading={isLoading}
            />
          </Grid>
        </Grid>
      </>
    )
  }
}
