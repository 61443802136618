import { Switch, TextField } from '@mui/material'
import { CustomAccordion, ListSelector } from '@wavetronix/common-components'
import { getProductCategory } from '../../Utils/ProductsMap'
import { containsSubstring } from '../../Utils/StringExtensions'

export const DEFAULT_INVENTORY_FILTER = {
  itemNumber: '',
  onlyShortages: false,
  products: []
}

const classes = {
  accordionList: {
    marginTop: '15px',
    borderRadius: '5px',
    width: '100%',
    boxShadow: 'none',
    border: '1px solid rgb(196, 196, 196)',
    '&:before': {
      display: 'none'
    }
  },
  accordionSummary: {
    width: '100%',
    borderRadius: '5px',
    minHeight: '40px'
  },
  accordionDetails: { padding: '0px', margin: '0px', marginTop: '0px' }
}

export const filterInventory = (filter, inventory) => {
  return inventory
    ? inventory.filter(i => {
        let result =
          (containsSubstring(i.itemNumber, filter.itemNumber) || filter.itemNumber === '') &&
          ((filter.onlyShortages === true && i.hasShortages === true) || filter.onlyShortages === false) &&
          (filter.products.map(p => p.value).includes(getProductCategory(i.itemNumber)) || filter.products.length === 0)

        return result
      })
    : []
}

export default function InventoryFilterDrawer({ filter, setFilter, products }) {
  const onCheckChange = (id, checked) => setFilter(f => ({ ...f, [id]: checked }))

  return (
    <>
      <TextField
        style={{ width: '100%' }}
        label={'Item Number'}
        size='small'
        value={filter.itemNumber}
        variant='outlined'
        onChange={e => setFilter(f => ({ ...f, itemNumber: e.target.value }))}
      />

      <CustomAccordion
        defaultExpanded
        title='Products'
        style={classes.accordionList}
        titleStyle={classes.accordionSummary}
        detailStyle={classes.accordionDetails}
        children={
          <ListSelector
            selectedOptions={filter.products}
            options={products.map(p => ({ value: p, id: p }))}
            onChange={e =>
              setFilter(f => {
                return { ...f, products: [...e] }
              })
            }
          />
        }
      />

      <div style={{ marginTop: '10px' }}>
        <Switch color='primary' checked={filter.onlyShortages} onChange={e => onCheckChange('onlyShortages', e.target.checked)} />
        Show Only Shortages
      </div>
    </>
  )
}
