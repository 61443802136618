import { OpenInNewOutlined } from '@mui/icons-material'
import React from 'react'

export default function SubstitutesList({ substitutes, newTab, partShortages }) {
  return substitutes && substitutes.length > 0
    ? substitutes.map((sub, i) => (
        <div title={substitutes.join(', ')} key={sub}>
          <a
            href={`../part?itemNumber=${sub}&shortages=${partShortages ? partShortages[sub] : ''}`}
            rel='noreferrer'
            target={newTab ? '_blank' : '_self'}>
            {sub} {newTab ? <OpenInNewOutlined style={{ fontSize: '13px' }} /> : null}
          </a>
          {i === substitutes.length - 1 ? '' : ', '}
        </div>
      ))
    : 'No Substitutes'
}
