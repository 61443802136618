import { FormControlLabel, Menu, MenuItem, Switch } from '@mui/material'
import { RegularButton } from '@wavetronix/common-components'
import { useState } from 'react'
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined'
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined'

/** Download contents as a file
 * Source: https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
 */

export default function ConfigureMenu({
  setLabelVisible,
  labelVisible,
  setShowMisc,
  showMisc,
  setWarehouseOnlyToggle,
  warehouseOnlyToggle,
  classes
}) {
  const [configAnchor, setConfigAnchor] = useState(null)
  const configOpen = Boolean(configAnchor)

  function handleConfigClick(event) {
    setConfigAnchor(event.currentTarget)
  }
  const handleConfigClose = () => {
    setConfigAnchor(null)
  }

  return (
    <>
      <div style={{ marginLeft: '15px' }}>
        <RegularButton onClick={handleConfigClick}>
          Configure
          {configOpen ? (
            <ArrowDropUpOutlinedIcon style={{ marginLeft: '10px' }} />
          ) : (
            <ArrowDropDownOutlinedIcon style={{ marginLeft: '10px' }} />
          )}
        </RegularButton>
        <Menu anchorEl={configAnchor} open={configOpen} onClose={handleConfigClose}>
          <MenuItem
            sx={classes.menuItem}
            disableRipple={true}
            onClick={() => {
              handleConfigClose()
            }}
            children={
              <FormControlLabel
                label='Labels'
                control={<Switch onChange={setLabelVisible} checked={labelVisible} color='primary' />}
              />
            }></MenuItem>
          <MenuItem
            sx={classes.menuItem}
            disableRipple={true}
            onClick={() => {
              handleConfigClose()
            }}
            children={
              <FormControlLabel
                label='Show Misc'
                control={<Switch checked={showMisc} onChange={setShowMisc} color='primary' />}
              />
            }></MenuItem>
          <MenuItem
            sx={classes.menuItem}
            disableRipple={true}
            onClick={() => {
              handleConfigClose()
            }}
            children={
              <FormControlLabel
                label='Warehouse Only'
                control={<Switch checked={warehouseOnlyToggle} onChange={setWarehouseOnlyToggle} color='primary' />}
              />
            }></MenuItem>
        </Menu>
      </div>
    </>
  )
}
