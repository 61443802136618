import { useMsal } from '@azure/msal-react'
import { Box, Divider, Menu, MenuItem, Tooltip } from '@mui/material'
import { acquireAccessToken, CustomSelect, parseJwt, RegularButton, SnackbarVariants } from '@wavetronix/common-components'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import Upload from 'rc-upload'
import { useState, useEffect } from 'react'
import { env } from '../../index.js'
import { getProductCategory } from '../../Utils/ProductsMap'
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined'
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined'
import DemandApi from '../../Api/DemandApi'
import { filterInventory } from '../Drawers/InventoryFilterDrawer'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'

/** Download contents as a file
 * Source: https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
 */
export function downloadBlob(content, filename, contentType) {
  // Create a blob
  var blob = new Blob([content], { type: contentType })
  var url = URL.createObjectURL(blob)

  // Create a link to download it
  var pom = document.createElement('a')
  pom.href = url
  pom.setAttribute('download', filename)
  pom.click()
}

const MonthsFullName = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
const TimeRange = [1, 3, 6, 12, 24]

export default function OptionsMenu({
  setWarehouseOnlyData,
  setWithIncomingData,
  missingRevisions,
  setMissingRevisions,
  demandResponses,
  setDemandResponses,
  warehouseOnlyToggle,
  cleanData,
  months,
  setMonths,
  classes,
  filter,
  openMissingRevisionModal
}) {
  const [uploadToken, setUploadToken] = useState('')
  const { instance, accounts } = useMsal()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [uploadingBom, setUploadingBom] = useState(false)
  const [uploadingBomKey, setUploadingBomKey] = useState('')
  const [uploadingDemand, setUploadingDemand] = useState(false)
  const [uploadingDemandKey, setUploadingDemandKey] = useState('')
  const [downloadWODemand, setDownloadWODemand] = useState(false)
  const [uploadingAveragePrice, setUploadingAveragePrice] = useState(false)
  const [uploadingAveragePriceKey, setUploadingAveragePriceKey] = useState('')

  const [actionAnchor, setActionAnchor] = useState(null)
  const actionOpen = Boolean(actionAnchor)

  function handleActionClick(event) {
    setActionAnchor(event.currentTarget)
  }
  const handleActionClose = () => {
    setActionAnchor(null)
  }

  function onUploadBomComplete() {
    closeSnackbar(uploadingBomKey)
    setUploadingBom(false)
  }

  function onUploadAveragePriceComplete() {
    closeSnackbar(uploadingAveragePriceKey)
    setUploadingAveragePrice(false)
  }

  function onRetrieveFillableDemandComplete() {
    closeSnackbar(uploadingDemandKey)
    setUploadingDemand(false)
  }

  useEffect(() => {
    async function resetUploadToken() {
      let token = await acquireAccessToken(instance, accounts, env)
      setUploadToken(token)
    }
    if (!uploadToken || parseJwt(uploadToken).exp < Date.now() / 1000) {
      console.log('resetting token')
      resetUploadToken()
    }
  }, [instance, accounts, uploadToken])

  const bomSuccess = res => {
    onUploadBomComplete()
    enqueueSnackbar('Successfully uploaded master bill of materials.', SnackbarVariants.SUCCESS)
  }
  const averagePriceSuccess = res => {
    onUploadAveragePriceComplete()
    enqueueSnackbar('Successfully uploaded average price file', SnackbarVariants.SUCCESS)
  }

  const onSuccessSales = res => {
    onRetrieveFillableDemandComplete()
    setWarehouseOnlyData(res[0].data)
    setWithIncomingData(res[1].data)
    if (warehouseOnlyToggle) {
      setDemandResponses(res[0].data)
    } else {
      setDemandResponses(res[1].data)
    }
  }

  const onSuccessWork = res => {
    onRetrieveFillableDemandComplete()
    setWarehouseOnlyData(res[0].data.fillableDemand)
    setWithIncomingData(res[1].data.fillableDemand)
    setMissingRevisions({ includeWarehouse: res[0].data.missingRevisions, excludeWarehouse: res[1].data.missingRevisions })
    if (res[0].data.missingRevisions > 0 || res[1].data.missingRevisions.length > 0) {
      enqueueSnackbar('Missing revisions found', {
        variant: 'warning',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        }
      })
    }
    if (warehouseOnlyToggle) {
      setDemandResponses(res[0].data.fillableDemand)
    } else {
      setDemandResponses(res[1].data.fillableDemand)
    }
  }

  const getSalesOrdersFillable = async () => {
    let key = enqueueSnackbar('Retrieving Sales Order Demand...', SnackbarVariants.LOADING)
    setUploadingDemand(true)
    await Promise.all([
      DemandApi.getSalesOrdersFillable(instance, accounts, true),
      DemandApi.getSalesOrdersFillable(instance, accounts, false)
    ])
      .then(res => {
        closeSnackbar(key)
        onSuccessSales(res)
      })
      .catch(e => {
        closeSnackbar(key)
        enqueueSnackbar(`${e.response.data}`, SnackbarVariants.ERROR)
      })
      .finally(() => setUploadingDemand(false))
  }

  const getWorkOrdersFillable = async () => {
    let key = enqueueSnackbar('Retrieving Work Order Demand...', SnackbarVariants.LOADING)
    setUploadingDemand(true)
    await Promise.all([
      DemandApi.getWorkOrdersFillable(instance, accounts, true),
      DemandApi.getWorkOrdersFillable(instance, accounts, false)
    ])
      .then(res => {
        closeSnackbar(key)
        onSuccessWork(res)
      })
      .catch(e => {
        closeSnackbar(key)
        enqueueSnackbar(`${e.response.data}`, SnackbarVariants.ERROR)
      })
      .finally(() => setUploadingDemand(false))
  }

  const generateDemandFile = async () => {
    let csvContent = 'Part Number,Rev,Desc,Avg Price'
    let currentMonth = new Date().getUTCMonth()
    for (let i = 0; i < months; i++) {
      csvContent += `,${MonthsFullName[(currentMonth + i) % 12]}`
    }
    csvContent += '\n'
    downloadBlob(csvContent, 'InputDemand.csv', 'text/csv;charset=utf-8;')
  }

  const exportPartShortages = async () => {
    let columns = demandResponses[0].requestedQuantities.length
    let shortagesTable = {}
    let descriptions = {}
    for (let column = 0; column < columns; column++) {
      for (let response of filterInventory(filter, demandResponses)) {
        for (let itemNumber in response.shortages[column]) {
          if (!(itemNumber in shortagesTable)) {
            descriptions[itemNumber] = response.shortages[column][itemNumber].description
            shortagesTable[itemNumber] = new Array(columns).fill(0)
          }
          shortagesTable[itemNumber][column] += response.shortages[column][itemNumber].shortage
        }
      }
    }
    let csvContent = `Item Number,Description,${cleanData.map(item => item.month).join(',')},Overall\n`
    for (let itemNumber in shortagesTable) {
      csvContent += `${itemNumber.toUpperCase()},${descriptions[itemNumber].replaceAll(',', ' ')},`
      csvContent += `${shortagesTable[itemNumber].join(',')}\n`
    }
    downloadBlob(csvContent, 'Shortages.csv', 'text/csv;charset=utf-8;')
  }

  const exportFillableDemand = async () => {
    if (demandResponses) {
      let csvContent = `Item Number,Revision,Category,Description,${cleanData.map(item => item.month).join(',')},Summary\n`
      for (let response of filterInventory(filter, demandResponses)) {
        let category = getProductCategory(response.itemNumber)
        csvContent += `${response.itemNumber} Total Demand,${response.revision},${category},${response.description},`
        let monthlyRequested = response.requestedQuantities.map(quantity => `$${response.averagePrice * quantity}`)
        csvContent += `${monthlyRequested.join(',')}\n`
        csvContent += `${response.itemNumber} Fillable Demand,${response.revision},${category},${response.description},`
        let monthlyFillable = response.fillableQuantities.map(quantity => `$${response.averagePrice * quantity}`)
        csvContent += `${monthlyFillable.join(',')}\n`
        csvContent += `${response.itemNumber} Backfillable Demand,${response.revision},${category},${response.description},`
        let monthlyBackfillable = response.backfillableQuantities.map(quantity => `$${response.averagePrice * quantity}`)
        csvContent += `${monthlyBackfillable.join(',')}\n`
        csvContent += `${response.itemNumber} Total Demand Quantities,${response.revision},${category},${response.description},`
        csvContent += `${response.requestedQuantities.join(',')}\n`
        csvContent += `${response.itemNumber} Fillable Demand Quantities,${response.revision},${category},${response.description},`
        csvContent += `${response.fillableQuantities.join(',')}\n`
        csvContent += `${response.itemNumber} Backfillable Demand Quantities,${response.revision},${category},${response.description},`
        csvContent += `${response.backfillableQuantities.join(',')}\n`
      }
      downloadBlob(
        csvContent,
        `${warehouseOnlyToggle ? 'WarehouseOnly' : 'IncomingOrders'}FillableDemand.csv`,
        'text/csv;charset=utf-8;'
      )
    }
  }

  const downloadAveragePriceFile = async () => {
    let token = await acquireAccessToken(instance, accounts, env)

    axios({
      url: `${env.urls.inventoryProjectorURL}/api/inventory/download/averageprice`, //your url,
      headers: { Authorization: `Bearer ${token}` },
      method: 'GET',
      responseType: 'blob' // important
    }).then(response => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data)

      // create "a" HTML element with href to file & click
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', 'Average Price by Item.xlsx') //or any other extension
      document.body.appendChild(link)
      link.click()

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    })
  }
  return (
    <div>
      <RegularButton onClick={handleActionClick}>
        Options
        {actionOpen ? (
          <ArrowDropUpOutlinedIcon style={{ marginLeft: '10px' }} />
        ) : (
          <ArrowDropDownOutlinedIcon style={{ marginLeft: '10px' }} />
        )}
      </RegularButton>
      <Menu anchorEl={actionAnchor} open={actionOpen} onClose={handleActionClose}>
        <Divider textAlign='right' sx={{ ...classes.divider, marginBottom: '8px' }}>
          Projection
        </Divider>
        <Upload
          disabled={uploadingDemand}
          name='demand'
          method='POST'
          action={`${env.urls.inventoryProjectorURL}/api/demand`}
          headers={{ Authorization: `Bearer ${uploadToken}` }}
          accept='.csv,.xls,.xlsx'
          customRequest={customDemandRequest}
          onStart={() => {
            handleActionClose()
            let key = enqueueSnackbar('Retrieving fillable demand...', SnackbarVariants.LOADING)
            setUploadingDemandKey(key)
            setUploadingDemand(true)
          }}
          onSuccess={onSuccessWork}
          onError={e => {
            onRetrieveFillableDemandComplete()
            enqueueSnackbar(`${e.response.data}`, SnackbarVariants.ERROR)
          }}>
          <Box sx={{ ...classes.menuItem }}>Import Demand</Box>
        </Upload>
        <MenuItem
          disableRipple={true}
          sx={classes.menuItem}
          disabled={uploadingDemand}
          onClick={() => {
            handleActionClose()
            getSalesOrdersFillable()
          }}>
          Use Sales Orders
        </MenuItem>
        <MenuItem
          disableRipple={true}
          sx={classes.menuItem}
          disabled={uploadingDemand}
          onClick={() => {
            handleActionClose()
            getWorkOrdersFillable()
          }}>
          Use Work Orders
        </MenuItem>
        <Divider textAlign='right' sx={classes.divider}>
          Analyze
        </Divider>
        <MenuItem
          disableRipple={true}
          sx={classes.menuItem}
          disabled={demandResponses === null || demandResponses === undefined || cleanData === null || cleanData === undefined}
          onClick={() => {
            handleActionClose()
            exportFillableDemand()
          }}>
          Fillable Demand
        </MenuItem>
        <MenuItem
          disableRipple={true}
          sx={classes.menuItem}
          disabled={missingRevisions === null || missingRevisions === undefined}
          onClick={() => {
            handleActionClose()
            openMissingRevisionModal()
          }}>
          Missing Revisions
        </MenuItem>
        <MenuItem
          disableRipple={true}
          sx={classes.menuItem}
          disabled={demandResponses === null || demandResponses === undefined || cleanData === null || cleanData === undefined}
          onClick={() => {
            handleActionClose()
            exportPartShortages()
          }}>
          Part Shortages
          <Tooltip
            title={
              'Export part shortages for each month, the overall column is a representation of how much we are short to fulfill all orders. Months with a zero value and a non zero overall reflect not consuming products during the month, but that shortage is reflected in the overall.'
            }>
            <HelpOutlineIcon sx={{ marginLeft: 'auto', marginRight: '0px' }} />
          </Tooltip>
        </MenuItem>

        <Divider textAlign='right' sx={classes.divider}>
          Generate
        </Divider>

        <MenuItem
          disableRipple={true}
          sx={classes.menuItem}
          children={
            <div style={{ display: 'flex' }}>
              <CustomSelect
                style={{ width: '70px', marginRight: '14px' }}
                label='Months'
                value={months}
                options={TimeRange}
                onChange={e => setMonths(e.target.value)}
              />
              <div
                style={{ marginTop: '8px' }}
                onClick={() => {
                  handleActionClose()
                  generateDemandFile()
                }}>
                Fillable Demand
              </div>
            </div>
          }></MenuItem>
        <MenuItem
          disableRipple={true}
          sx={classes.menuItem}
          disabled={downloadWODemand}
          onClick={async () => {
            setDownloadWODemand(true)
            return await DemandApi.getWordOrdersDemandRequest(instance, accounts)
              .then(result => {
                setDownloadWODemand(false)
              })
              .catch(error => {
                setDownloadWODemand(false)
                enqueueSnackbar(`${error.response.data}`, SnackbarVariants.ERROR)
              })
          }}>
          Work Orders Demand
        </MenuItem>
        <MenuItem disableRipple={true} sx={classes.menuItem} onClick={downloadAveragePriceFile}>
          <div>Average Price by Item</div>
          <Tooltip title={'Download the CURRENT average price file from work order projection'}>
            <HelpOutlineIcon sx={{ marginLeft: 'auto', marginRight: '0px' }} />
          </Tooltip>
        </MenuItem>

        <Divider textAlign='right' sx={classes.divider}>
          Update
        </Divider>
        <Upload
          disabled={uploadingBom}
          name='bom'
          method='POST'
          action={`${env.urls.inventoryProjectorURL}/api/billofmaterials`}
          headers={{ Authorization: `Bearer ${uploadToken}` }}
          customRequest={customFileUploadRequest}
          accept='.csv'
          onStart={() => {
            handleActionClose()
            let key = enqueueSnackbar('Uploading Bill of Materials...', SnackbarVariants.LOADING)
            setUploadingBomKey(key)
            setUploadingBom(true)
          }}
          onSuccess={bomSuccess}
          onError={error => {
            onUploadBomComplete()
            enqueueSnackbar(`${error.response.data}`, SnackbarVariants.ERROR)
          }}>
          <Box sx={{ ...classes.menuItem }}>Bill of Materials</Box>
        </Upload>
        <Upload
          disabled={uploadingAveragePrice}
          name='file'
          method='POST'
          action={`${env.urls.inventoryProjectorURL}/api/inventory/averageprice`}
          headers={{ Authorization: `Bearer ${uploadToken}` }}
          customRequest={customFileUploadRequest}
          accept='.xlsx'
          onStart={() => {
            handleActionClose()
            let key = enqueueSnackbar('Uploading Average Price per Item...', SnackbarVariants.LOADING)
            setUploadingAveragePriceKey(key)
            setUploadingAveragePrice(true)
          }}
          onSuccess={averagePriceSuccess}
          onError={error => {
            onUploadAveragePriceComplete()
            enqueueSnackbar(`${error.response.data}`, SnackbarVariants.ERROR)
          }}>
          <Box sx={{ ...classes.menuItem, display: 'flex' }}>
            <div>Average Price by Item</div>
            <Tooltip title={'Upload a NEW average price file for work orders projection'}>
              <HelpOutlineIcon sx={{ marginLeft: 'auto', marginRight: '0px' }} />
            </Tooltip>
          </Box>
        </Upload>
      </Menu>
    </div>
  )
}

async function customFileUploadRequest({
  action,
  data,
  file,
  filename,
  headers,
  onError,
  onProgress,
  onSuccess,
  withCredentials
}) {
  const formData = new FormData()
  if (data) {
    Object.keys(data).forEach(key => {
      formData.append(key, data[key])
    })
  }
  formData.append(filename, file)

  let fileUploadTask = axios.post(`${action}`, formData, {
    withCredentials,
    headers,
    onUploadProgress: ({ total, loaded }) => {
      onProgress({ percent: Math.round((loaded / total) * 100).toFixed(2) }, file)
    }
  })

  await Promise.all([fileUploadTask])
    .then(values => {
      onSuccess(values)
    })
    .catch(onError)

  return {
    abort() {
      console.log('upload progress is aborted.')
    }
  }
}

async function customDemandRequest({ action, data, file, filename, headers, onError, onProgress, onSuccess, withCredentials }) {
  const formData = new FormData()
  if (data) {
    Object.keys(data).forEach(key => {
      formData.append(key, data[key])
    })
  }
  formData.append(filename, file)

  let demandWithOrdersTask = axios.post(`${action}?warehouseOnly=false`, formData, {
    withCredentials,
    headers,
    onUploadProgress: ({ total, loaded }) => {
      onProgress({ percent: Math.round((loaded / total) * 100).toFixed(2) }, file)
    }
  })
  let demandWarehouseOnlyTask = axios.post(`${action}?warehouseOnly=true`, formData, {
    withCredentials,
    headers,
    onUploadProgress: ({ total, loaded }) => {
      onProgress({ percent: Math.round((loaded / total) * 100).toFixed(2) }, file)
    }
  })
  await Promise.all([demandWarehouseOnlyTask, demandWithOrdersTask])
    .then(values => {
      onSuccess(values)
    })
    .catch(onError)

  return {
    abort() {
      console.log('upload progress is aborted.')
    }
  }
}
