export const ColorOptions = {
  DeepWoodGreen: '#317664',
  OliveGreen: '#ADAD76',
  ColdViolate: '#7777ae',
  ColdMaroon: '#905484',
  CoolTeal: '#57c1bc',
  MidSkyBlue: '#32abd0',
  DuskSky: '#004660',
  DustyBlue: '#6e9fa5',
  DarkGrayGreen: '#41676d',
  DarkOliveGreen: '#538F5F',
  DustyPink: '#C97FA5'
}
