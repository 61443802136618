import { CenteredDiv, WtxColors, SetupProvider, createWtxAuthInstance } from '@wavetronix/common-components'
import axios from 'axios'
import React from 'react'
import PageRouter from './Components/PageRouter'
import { createRoot } from 'react-dom/client'
import './index.css'
import generateEnv from './env/generateEnv.js'

axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'

if (window.location.pathname === '/logout') {
  window.localStorage.clear()
  window.location.href = window.location.origin
}

export const env = await generateEnv()

const container = document.getElementById('root')
const root = createRoot(container) // createRoot(container!) if you use TypeScript

if (env.isError) {
  root.render(
    <CenteredDiv>
      <h3 style={{ color: WtxColors.INNOVATION_RED }}>{`${env.message}`}</h3>
    </CenteredDiv>
  )
} else {
  const msalInstance = await createWtxAuthInstance(env)

  root.render(
    <SetupProvider env={env} allowedRoles={['Compass User']} msalInstance={msalInstance}>
      <PageRouter />
    </SetupProvider>
  )
}
