import axios from 'axios'
import { env } from '../index.js'
import { acquireAccessToken } from '@wavetronix/common-components'

let instance = null

class InventoryApi {
  getInventory = async (msalInstance, accounts) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios.get(`${env.urls.inventoryProjectorURL}/api/inventory`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      timeout: 30000
    })
  }

  uploadInventory = async (msalInstance, accounts, inventory) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios.put(`${env.urls.inventoryProjectorURL}/api/inventory`, inventory, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      timeout: 30000
    })
  }

  resetInventory = async (msalInstance, accounts) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios.put(
      `${env.urls.inventoryProjectorURL}/api/inventory/reset`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`
        },
        timeout: 30000
      }
    )
  }

  getInventoryAreEqual = async (msalInstance, accounts) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios.get(`${env.urls.inventoryProjectorURL}/api/inventory/inventoryareequal`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      timeout: 30000
    })
  }
}

const getInstance = () => {
  if (instance == null) {
    instance = new InventoryApi()
  }
  return instance
}

export default getInstance()
