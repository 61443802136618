export const containsSubstring = (original, substring) => {
  if (original && substring) {
    if (!String.prototype.includes) {
      return original.toLowerCase().indexOf(substring.toLowerCase()) !== -1
    } else {
      return original.toLowerCase().includes(substring.toLowerCase())
    }
  } else {
    return false
  }
}
