import { MenuAppBar } from '@wavetronix/common-components'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { useState } from 'react'
import { env } from '../index.js'
import PartInfoPage from './Pages/PartInfoPage'
import ProjectionPage from './Pages/ProjectionPage'
import InventoryManagement from './Pages/InventoryManagement'
import version from '../env/version.json'

export const URL_MAP = {
  projections: 0,
  inventory: 1,
  '': 0
}

function PageRouter() {
  const [state, setState] = useState({
    current: window.location.pathname.substring(1)
  })
  return (
    <div>
      <MenuAppBar env={env} appHeader='Inventory Revenue Projector' />
      <div className='content'>
        <BrowserRouter>
          <Switch>
            <Route path='/' exact>
              <Redirect to='/projections' />
            </Route>
            <Route
              path='/projections'
              exact
              render={() => (
                <ProjectionPage
                  tabState={state}
                  changeTabState={s => {
                    setState(s)
                  }}
                />
              )}
            />
            <Route path='/part' component={PartInfoPage} />
            <Route
              path='/inventory'
              exact
              render={() => (
                <InventoryManagement
                  tabState={state}
                  changeTabState={s => {
                    setState(s)
                  }}
                />
              )}
            />
            <Route path='/version' exact render={() => <div>{version.version}</div>} />
          </Switch>
        </BrowserRouter>
      </div>
    </div>
  )
}
export default PageRouter
