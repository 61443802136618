// export const localEnv = {
//   runMode: 'dev',
//   clientId: 'c137b63c-14aa-42ba-95f2-ea01aa5c1241',
//   urls: {
//     inventoryProjectorURL: 'https://wtx-inventoryprojector-rest.wtxdev.com'
//   },
//   basicAuthentication: {}
// }

// export const localEnv = {
//   runMode: 'int',
//   clientId: 'bd23e135-86bd-4def-b6ba-85542271f84a',
//   urls: {
//     inventoryProjectorURL: 'https://wtx-inventoryprojector-rest.wtxint.com'
//   },
//   basicAuthentication: {}
// }

export const localEnv = {
  runMode: 'prod',
  clientId: '5369a37d-0d10-471b-b2b8-16d74eb9ff6d',
  urls: {
    inventoryProjectorURL: 'https://wtx-inventoryprojector-rest.wavetronix.com'
  },
  basicAuthentication: {}
}
