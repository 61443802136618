const Products = {
  CLK650: 'CLK 650',
  HD: 'HD',
  XP20: 'XP20',
  XP21: 'XP21',
  CLK600: 'CLK 600',
  CLK656: 'CLK 656',
  CLK_MISC: 'Click Misc.',
  ARC: 'ARC',
  MATRIX: 'Matrix',
  ADVANCE: 'Advance',
  ARC5: 'ARC5',
  ARC6: 'ARC6'
}

function isProduct(s) {
  if (s === 'Misc.') {
    return true
  }
  for (let k in Products) {
    if (Products[k] === s) {
      return true
    }
  }
  return false
}

const ITEM_NUMBER_PRODUCTS_MAP = {
  '101-0403': Products.HD,
  '101-0415': Products.HD,
  '101-0416': Products.HD,
  '101-0417': Products.HD,
  '102-0425': Products.HD,
  '102-0438': Products.HD,
  '102-0440': Products.HD,
  '102-0551': Products.HD,
  '102-0552': Products.HD,
  '102-0553': Products.HD,
  '102-0554': Products.HD,
  '102-0556': Products.HD,
  '102-0568': Products.HD,
  '102-0557': Products.MATRIX,
  'SS-225': Products.MATRIX,
  'SS-B01-0008': Products.MATRIX,
  '360-0283': Products.ADVANCE,
  'SS-200E': Products.ADVANCE,
  'SS-200V': Products.ADVANCE,
  'CLK-B02-0110': Products.HD,
  '101-0451': Products.XP20,
  '101-0479': Products.XP21,
  '102-0402': Products.CLK600,
  '102-0423': Products.CLK600,
  '102-0416': Products.CLK650,
  '102-0530': Products.CLK650,
  '102-0549': Products.CLK650,
  '102-0451': Products.CLK656,
  '102-0462': Products.CLK656,
  '102-0531': Products.CLK656,
  '100-0277': Products.CLK_MISC,
  '100-0281': Products.CLK_MISC,
  '102-0401': Products.CLK_MISC,
  '102-0435': Products.CLK_MISC,
  '102-0498': Products.CLK_MISC,
  '205-0000': Products.CLK_MISC,
  'CLK-100': Products.CLK_MISC,
  'CLK-104': Products.CLK_MISC,
  'CLK-120': Products.CLK_MISC,
  'CLK-200': Products.CLK_MISC,
  'CLK-201': Products.CLK_MISC,
  'CLK-202': Products.CLK_MISC,
  'CLK-204': Products.CLK_MISC,
  'CLk-210': Products.CLK_MISC,
  'CLK-222': Products.CLK_MISC,
  'CLK-223': Products.CLK_MISC,
  'CLK-224': Products.CLK_MISC,
  'CLK-230': Products.CLK_MISC,
  'CLK-230-02': Products.CLK_MISC,
  'CLK-301': Products.CLK_MISC,
  'CLK-304': Products.CLK_MISC,
  'CLK-400': Products.CLK_MISC,
  'SS-C10-0016': Products.CLK_MISC,
  '101-0457': Products.ARC,
  '101-0467': Products.ARC,
  '101-0470': Products.ARC,
  '102-0439': Products.ARC,
  '102-0480': Products.ARC,
  '102-0486': Products.ARC,
  '102-0509': Products.ARC,
  '102-0540': Products.ARC,
  '102-0563': Products.ARC5,
  '102-0587': Products.ARC6,
  '102-0594': Products.ARC6
}

function getProductCategory(itemNumber) {
  if (ITEM_NUMBER_PRODUCTS_MAP[itemNumber]) {
    return ITEM_NUMBER_PRODUCTS_MAP[itemNumber]
  }
  return 'Misc.'
}

export { Products, isProduct, getProductCategory }
