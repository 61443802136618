import axios from 'axios'
import { env } from '../index.js'
import { acquireAccessToken } from '@wavetronix/common-components'

let instance = null

class DemandApi {
  getSalesOrdersFillable = async (msalInstance, accounts, warehouseOnly) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios.get(`${env.urls.inventoryProjectorURL}/api/demand/salesorders?warehouseOnly=${warehouseOnly}`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      timeout: 500000
    })
  }

  getWorkOrdersFillable = async (msalInstance, accounts, warehouseOnly) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios.get(`${env.urls.inventoryProjectorURL}/api/demand/workorders?warehouseOnly=${warehouseOnly}`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      timeout: 500000
    })
  }

  getWordOrdersDemandRequest = async (msalInstance, accounts) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    var form = new FormData()
    return await axios({
      url: `${env.urls.inventoryProjectorURL}/api/demand/workorders/demandfile`,
      method: 'POST',
      data: form,
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(response => {
      const href = URL.createObjectURL(response.data)

      // create "a" HTLM element with href to file & click
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', 'Work Order Demand Requests.xlsx') //or any other extension
      document.body.appendChild(link)
      link.click()

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    })
  }
}

const getInstance = () => {
  if (instance == null) {
    instance = new DemandApi()
  }
  return instance
}

export default getInstance()
